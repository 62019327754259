import request from '@/utils/request'

// 获取组织机构树形数据
export function WeGetOrganTree (data) {
  return request({
    url: 'Organ/WeGetOrganTree',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}
// 获取组织机构树
export function GetOrganTree (query) {
  return request({
    url: 'Organ/GetOrganTree',
    method: 'get',
    params: query
  })
}

// 获取组织机构
export function WeGetAppletOrganList (query) {
  return request({
    url: 'Organ/WeGetAppletOrganList',
    method: 'get',
    params: query
  })
}

export function GetChildOrgansNullToken (query) {
  return request({
    url: 'Organ/GetChildOrgansNullToken',
    method: 'get',
    params: query
  })
}